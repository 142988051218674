<template>
	<v-container fluid fill-height class="background_kn">
		<v-col cols="12">
			<v-row align="center" justify="center" class="fill-height">
				<v-col cols="auto">
					<v-card :class="`px-md-10 py-md-8 ${isMobile ? 'elevation-0 transparent' : 'elevation-16'}`" width="500">
						<v-container>
							<v-row>
								<v-col cols="12">
									<h3>{{ $t('verification.titre') }}</h3>
								</v-col>
								<v-col cols="12">
									<h3>{{ $t('verification.confirmationDemande') }}</h3>
								</v-col>
							</v-row>
							<v-row class="mt-3">
								<v-col cols="auto">
									<div class="d-flex flex-column">
										<h4 style="font-weight: normal" class="d-flex">
											{{ $t('verification.emailCodeTexte1') }}
										</h4>
										<div class="mt-1 font-weight-bold d-flex" style="color:#155eef">
											{{ $store.state.emailAdministrateur }}
										</div>
									</div>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="auto">
									<h4 style="font-weight: normal">
										{{ $t('verification.emailCodeTexte2') }}
									</h4>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="auto">
									{{ $t('verification.verifierSpam') }}
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
	</v-container>
</template>

<script>
export default {
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.name == 'xs';
		},
	},
};
</script>