import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		emailAdministrateur: null,
	},
	getters: {},
	mutations: {
		emailAdministrateur(state, email) {
			state.emailAdministrateur = email;
		},
	},
	actions: {},
	modules: {},
});
