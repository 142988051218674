import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#155eef',
				secondary: '#B6D0E4',
				accent: '#F7E372',
				background: '#F1EDE4',
			},
		},
	},
});
