<template>
	<v-container fluid fill-height class="background_kn">
		<v-row align="center" justify="center">
			<v-col cols="auto">
				<v-card :class="`px-md-10 py-md-8 ${isMobile ? 'elevation-0 transparent pt-12 mt-16' : 'elevation-16'}`" width="600">
					<v-form v-if="messageVerificationDemande == 'OK'" ref="infosAdditionnelles" lazy-validation @submit.prevent>
						<v-container>
							<v-row>
								<v-col cols="12">
									<h3>
										{{ $t('parametrageCompteEssai.titre') }}
									</h3>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12">
									<h5 class="text-pre-line">
										{{ $t('parametrageCompteEssai.explicationParametrage') }}
									</h5>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12" md="8">
									<div class="required">
										{{ $t('parametrageCompteEssai.fonction') }}
									</div>
									<v-select
										v-model="parametresCompte.fonction"
										:items="listeFonctions"
										item-value="code"
										item-text="display"
										outlined
										dense
										hide-details="auto"
										:rules="[estObligatoire(parametresCompte.fonction)]"></v-select>
								</v-col>
								<v-col cols="12" md="4">
									<div class="required">
										{{ $t('parametrageCompteEssai.taille') }}
									</div>
									<v-select
										v-model="parametresCompte.taille"
										:items="listeTailles"
										item-value="code"
										item-text="display"
										outlined
										dense
										hide-details="auto"
										:rules="[estObligatoire(parametresCompte.taille)]"></v-select>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12">
									<div class="required">
										{{ $t('parametrageCompteEssai.secteurActivite') }}
									</div>
									<v-select
										v-model="parametresCompte.secteurActivite"
										:items="listeSecteurs"
										item-value="code"
										item-text="display"
										outlined
										dense
										hide-details="auto"
										:rules="[estObligatoire(parametresCompte.secteurActivite)]"
										@change="changeSecteur()"></v-select>
								</v-col>
							</v-row>

							<v-row v-if="listeDomaines.length > 0">
								<v-col cols="12">
									<v-radio-group v-model="parametresCompte.domaine" hide-details="auto" class="mt-0" :rules="[estObligatoire(parametresCompte.domaine)]">
										<v-radio v-for="domaineDuSecteur in listeDomaines" :key="domaineDuSecteur.code" :label="domaineDuSecteur.display" :value="domaineDuSecteur.code">
											<template #label>
												<div style="font-size: 90%;" v-html="domaineDuSecteur.display"></div>
											</template>
										</v-radio>
									</v-radio-group>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12" md="auto" class="mx-auto mt-6">
									<v-btn
										block
										tile
										min-width="100px"
										color="primary"
										@click="parametrerCompte()">
										<span style="color:white" class="px-2 text-uppercase">
											{{ $t('parametrageCompteEssai.boutonValider') }}
										</span>
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
					<div v-else-if="messageVerificationDemande == 'OLD_ENCOURS'">
						<v-row align="center" justify="center">
							<v-col cols="auto">
								<h3>
									{{ $t('parametrageCompteEssai.oldEnCours') }}
								</h3>
							</v-col>
						</v-row>
					</div>
					<div v-else-if="messageVerificationDemande == 'OLD_ACTIVE'">
						<v-row align="center" justify="center">
							<v-col cols="auto">
								<h3>
									{{ $t('parametrageCompteEssai.oldActive') }}
								</h3>
							</v-col>
						</v-row>
					</div>
					<div v-else-if="messageVerificationDemande == 'OLD_EXPIREE'">
						<v-row align="center" justify="center">
							<v-col cols="auto">
								<h3>
									{{ $t('parametrageCompteEssai.oldExpire') }}
								</h3>
							</v-col>
						</v-row>
					</div>
				</v-card>
			</v-col>
		</v-row>
		<Spinner :est-visible="loading"></Spinner>
	</v-container>
</template>

<script>
import formulaireService from '@/services/formulaireService';
import Spinner from '@/components/Spinner.vue';
export default {
	name: 'ConfirmationPage',
	components: {
		Spinner,
	},
	props: {
		demande: {
			type: String,
		},
		code: {
			type: String,
		},
	},
	data() {
		return {
			parametresCompte: {
				idDemande: this.demande,
				codeVerification: this.code,
				fonction: null,
				taille: null,
				secteurActivite: null,
				domaine: null,
				initialisationCompte: true,
				informations: null,
			},

			listeFonctions: [],
			listeTailles: [],
			listeSecteurs: [],
			listeDomaines: [],

			loading: false,

			messageVerificationDemande: '',
			messageParametrageDemande: '',
		};
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.name == 'xs';
		},
	},
	methods: {
		estObligatoire(valeur) {
			return !!valeur || valeur === 0 || this.$t('champs.champsObligatoire');
		},
		changeSecteur() {
			this.chargeListeDomaines();
			this.showTitre = this.listeDomaines.length > 0;
		},
		async chargeListeTailles() {
			this.listeTailles = await formulaireService.donneListeTailles();
		},
		async chargeListeSecteurs() {
			this.listeSecteurs = await formulaireService.donneListeSecteurs();
		},
		async chargeListeDomaines() {
			this.listeDomaines = await formulaireService.donneListeDomaines(this.parametresCompte.secteurActivite);
		},
		async chargeListeFonctions() {
			this.listeFonctions = await formulaireService.donneListeFonctions();
		},
		async parametrerCompte() {
			if (!this.$refs.infosAdditionnelles.validate()) return;
			this.loading = true;
			try {
				this.messageParametrageDemande = await formulaireService.finaliserDemande(this.parametresCompte);
				await new Promise((resolve) => setTimeout(resolve, 3000));
				if (this.messageParametrageDemande === 'NOK') {
					this.$router.push({ name: 'formulaireDemande' });
				} else {
					this.$router.push({ name: 'confirmationParametrage' });
				}
			} catch (error) {
				console.error(this.$t('parametrageCompteEssai.erreur'), error);
			} finally {
				this.loading = false;
			}
		}
	},
	async mounted() {
		this.messageVerificationDemande = (await formulaireService.verifierDemande(this.demande, this.code)).resultat;
		if (this.messageVerificationDemande === 'NOK') this.$router.push({ name: 'formulaireDemande' });
		this.chargeListeTailles();
		this.chargeListeSecteurs();
		this.chargeListeFonctions();
	},
};
</script>
