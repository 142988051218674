<template>
	<v-container fluid fill-height class="background_kn">
		<v-row align="center" justify="center" class="fill-height mt-n16">
			<v-col cols="auto">
				<v-card :class="`px-md-10 py-md-8 ${isMobile ? 'elevation-0 transparent mt-16 pt-10' : 'elevation-16'}`" width="600">
					<v-form v-if="messageVerificationInvitation == 'OK'" ref="invite" lazy-validation @submit.prevent>
						<v-container>
							<v-row>
								<v-col cols="12">
									<h3 style="font-weight: normal">
										{{ $t('formulaireInvite.titre') }}
									</h3>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<h2>{{ $t('formulaireInvite.rejoindreCollegues') }}</h2>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<h3 style="font-weight: normal">
										{{ $t('formulaireInvite.invitationKaranext') }}
									</h3>
								</v-col>
							</v-row>
							<v-row align="center" justify="center" class="mt-6">
								<v-col cols="auto" class="text-center mx-auto">
									<div class="d-flex flex-row">
										<div class="d-flex">
											{{ $t('formulaireInvite.email') }}
										</div>
										<div class="d-flex font-weight-bold">
											{{ utilisateurInvite.email }}
										</div>
									</div>
								</v-col>
							</v-row>
							<v-row class="mt-6">
								<v-col cols="12" md="6">
									<div class="required">
										{{ $t('formulaireInvite.prenom') }}
									</div>
									<v-text-field
										v-model="utilisateurInvite.prenom"
										outlined
										dense
										hide-details="auto"
										:rules="[estObligatoire(utilisateurInvite.prenom)]"></v-text-field>
								</v-col>
								<v-col cols="12" md="6">
									<div class="required">
										{{ $t('formulaireInvite.nom') }}
									</div>
									<v-text-field
										v-model="utilisateurInvite.nom"
										outlined
										dense
										hide-details="auto"
										:rules="[estObligatoire(utilisateurInvite.nom)]"></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6">
									<div class="required">
										{{ $t('formulaireInvite.fonction') }}
									</div>
									<v-select
										v-model="utilisateurInvite.fonction"
										outlined
										dense
										hide-details="auto"
										:items="listeFonctions"
										item-text="display"
										:rules="[estObligatoire(utilisateurInvite.fonction)]"></v-select>
								</v-col>
								<v-col cols="12" md="6">
									<div>{{ $t('formulaireInvite.telephoneMobile') }}</div>
									<v-text-field
										v-model="utilisateurInvite.telMobile"
										id="phone"
										outlined
										dense
										hide-details="auto"
										:rules="[estNumeroTelephone(utilisateurInvite.telMobile)]">
									</v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="auto" class="mx-auto mt-6">
									<v-btn
										min-width="100px"
										color="primary"
										tile
										block
										@click="creerCompteInvite()">
										<span style="color:white" class="px-2 text-uppercase">
											{{ $t('formulaireInvite.boutonRejoindreCompte') }}
										</span>
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
					<v-row v-else-if="messageVerificationInvitation == 'OLD_INVITATION_ENCOURS'">
						<v-col cols="12">
							<h3>{{ $t('formulaireInvite.oldInvitationEncours') }}</h3>
						</v-col>
					</v-row>
					<v-row v-else-if="messageVerificationInvitation == 'OLD_INVITATION_ACTIVE'">
						<v-col cols="12">
							<h3>{{ $t('formulaireInvite.oldInvitationActive') }}</h3>
						</v-col>
					</v-row>
					<v-row v-else-if="messageVerificationInvitation == 'OLD__EXPIREE'">
						<v-col cols="12">
							<h3>{{ $t('formulaireInvite.oldExpiree') }}</h3>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		<Spinner :est-visible="loading"></Spinner>
	</v-container>
</template>

<script>
import formulaireService from '@/services/formulaireService';
import Spinner from '@/components/Spinner.vue';
export default {
	name: 'UtilisateurInvite',
	components: {
		Spinner
	},
	props: {
		demande: {
			type: String,
		},
		email: {
			type: String,
		},
	},
	data() {
		return {
			utilisateurInvite: {
				idDemande: this.demande,
				email: this.email,
				prenom: null,
				nom: null,
				fonction: null,
				telMobile: null,
			},

			messageVerificationInvitation: '',
			messageConfirmationInvitation: '',

			loading: false,

			listeFonctions: [],
		};
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.name == 'xs';
		},
	},
	methods: {
		estObligatoire(valeur) {
			return !!valeur || valeur === 0 || this.$t('champs.champsObligatoire');
		},
		estNumeroTelephone(valeur) {
			if (valeur && valeur.trim() !== '') {
				const pattern = /^(\+|00)?[0-9]+$/;
				return (!!valeur && pattern.test(valeur)) || this.$t('champs.champsTelephone');
			}
			return true;
		},
		async chargeListeFonctions() {
			this.listeFonctions = await formulaireService.donneListeFonctions();
		},
		async creerCompteInvite() {
			if (!this.$refs.invite.validate()) return;
			this.loading = true;
			try {
				this.messageConfirmationInvitation = await formulaireService.rejoindreCompteEssaiCommeInvite(this.utilisateurInvite);
				if (this.messageConfirmationInvitation === 'NOK') this.$router.push({ name: 'formulaireDemande' });
				location.reload();
			} catch (error) {
				console.error(this.$t('formulaireInvite.erreur'), error);
			} finally {
				this.loading = false;
			}
		},
	},
	async mounted() {
		this.messageVerificationInvitation = (await formulaireService.donneInvitation(this.demande, this.email)).resultat;
		if (this.messageVerificationInvitation === 'NOK' || this.messageVerificationInvitation === 'OLD_INIT' || this.messageVerificationInvitation === 'OLD_ENCOURS') this.$router.push({ name: 'formulaireDemande' });
		await this.chargeListeFonctions();
	},
};
</script>