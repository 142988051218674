<template>
	<v-container fluid fill-height class="background_kn">
		<v-row align="center" justify="center" class="fill-height mt-n16">
			<v-col cols="auto">
				<v-card :class="`px-md-10 py-md-8 ${isMobile ? 'elevation-0 transparent' : 'elevation-16'}`" width="600">
					<v-container>
						<v-row class="mt-5">
							<v-col cols="10" class="text-pre-line text-center mx-auto font-weight-bold">
								{{ $t('confirmationParametrage.parametresConfirmes') }}
							</v-col>
						</v-row>

						<v-row align="center" justify="center" class="mt-5">
							<v-col cols="12" md="6">
								<div class="d-flex flex-column">
									<div class="d-flex text-center">
										{{ $t('confirmationParametrage.aide') }}
									</div>
									<div class="d-flex justify-center mt-5">
										<v-btn
											class="pa-5 px-8"
											tile
											color="primary white--text"
											@click="redirectionVersPriseRDV()">
											<v-icon class="mr-1">mdi-calendar</v-icon>
											{{ $t('confirmationParametrage.boutonRdv') }}
										</v-btn>
									</div>
								</div>
							</v-col>
						</v-row>
						<v-row class="mt-1">
							<v-col cols="10" class="text-center mx-auto">
								<div class="d-flex justify-center">
									<a
										text
										class="text-decoration-none"
										rounded
										color="primary"
										href="https://www.karanext.com/">
										{{ $t('confirmationParametrage.boutonSkip') }}
										<v-icon color="primary">mdi-chevron-double-right</v-icon>
									</a>
								</div>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
		</v-row>
		<Spinner :est-visible="loading"></Spinner>
	</v-container>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
export default {
	components: {
		Spinner,
	},
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.name == 'xs';
		},
	},
	methods: {
		async redirectionVersPriseRDV() {
			this.loading = true;
			this.$router.push({ name: 'priseRdv' });
		},
	},
};
</script>