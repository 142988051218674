import Vue from 'vue';
import VueRouter from 'vue-router';
import FormulaireDemandeCompteEssai from '../views/FormulaireDemandeCompteEssai.vue';
import ConfirmationDemandeCompteEssai from '../views/ConfirmationDemandeCompteEssai.vue';
import ParametrageCompteEssai from '../views/ParametrageCompteEssai.vue';
import ConfirmationParametrageCompteEssai from '../views/ConfirmationParametrageCompteEssai.vue';
import InvitationsUtilisateurs from '../views/InvitationsUtilisateurs.vue';
import PriseRdv from '../views/PriseRdv.vue';
import FormulaireUtilisateurInvite from '../views/FormulaireUtilisateurInvite.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'formulaireDemande',
		component: FormulaireDemandeCompteEssai,
	},
	{
		path: '/confirmationDemande',
		name: 'confirmationDemande',
		component: ConfirmationDemandeCompteEssai,
	},
	{
		path: '/parametrageCompteEssai',
		name: 'parametrageCompteEssai',
		component: ParametrageCompteEssai,
		props: (route) => ({
			demande: route.query.demande,
			code: route.query.code,
		}),
	},
	{
		path: '/confirmationParametrage',
		name: 'confirmationParametrage',
		component: ConfirmationParametrageCompteEssai,
	},
	{
		path: '/invitationsUtilisateurs',
		name: 'invitationsUtilisateurs',
		component: InvitationsUtilisateurs,
		props: (route) => ({
			demande: route.query.demande,
			code: route.query.code,
		}),
	},
	{
		path: '/priseRdv',
		name: 'priseRdv',
		component: PriseRdv,
	},
	{
		path: '/utilisateurInvite',
		name: 'utilisateurInvite',
		component: FormulaireUtilisateurInvite,
		props: (route) => ({
			demande: route.query.demande,
			email: route.query.email,
		}),
	},
];

const router = new VueRouter({ routes });

export default router;
