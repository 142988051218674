<template>
	<v-container fluid class="background_kn">
		<vue-calendly v-if="!isMobile" :url="calendlyURL" height="700"></vue-calendly>
		<vue-calendly v-else class="mt-16" :url="calendlyMobileURL" height="700"></vue-calendly>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			calendlyURL: process.env.VUE_APP_CALENDLY_URL,
			calendlyMobileURL: process.env.VUE_APP_CALENDLY_URL_MOBILE,
		};
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.name == 'xs';
		},
	},
};
</script>