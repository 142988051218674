import reseauService from './reseauService';

const langue = navigator.language.split('-')[0] === process.env.VUE_APP_I18N_LOCALE ? process.env.VUE_APP_I18N_LOCALE : process.env.VUE_APP_I18N_FALLBACK_LOCALE;

const creerDemande = async (demande) => {
	const retour = await reseauService.donneInstanceAxios().request({
		method: 'post',
		url: '/Demandes',
		data: demande,
	});
	return retour.data;
};

const verifierDemande = async (idDemande, codeVerification) => {
	const retour = await reseauService.donneInstanceAxios().request({
		method: 'put',
		url: '/DemandesVerifier',
		data: {
			idDemande: idDemande,
			codeVerification: codeVerification,
		},
	});
	return retour.data;
};

const donneListeFonctions = async () => {
	const retour = await reseauService.donneInstanceAxios().request({
		method: 'get',
		url: '/Fonctions',
		params: {
			langue: langue,
		},
	});
	return retour.data;
};

const donneListeTailles = async () => {
	const retour = await reseauService.donneInstanceAxios().request({
		method: 'get',
		url: '/Tailles',
		params: {
			langue: langue,
		},
	});
	return retour.data;
};

const donneListeSecteurs = async () => {
	const retour = await reseauService.donneInstanceAxios().request({
		method: 'get',
		url: '/SecteursActivites',
		params: {
			langue: langue,
		},
	});
	return retour.data;
};

const donneListeDomaines = async (secteur) => {
	const retour = await reseauService.donneInstanceAxios().request({
		method: 'get',
		url: '/Domaines',
		params: {
			secteurActivite: secteur,
			langue: langue,
		},
	});
	return retour.data;
};

const finaliserDemande = async (parametresCompte) => {
	const retour = await reseauService.donneInstanceAxios().request({
		method: 'put',
		url: '/Demandes',
		data: parametresCompte,
	});
	return retour.data;
};

const creerInvitations = async (invitations) => {
	const retour = await reseauService.donneInstanceAxios().request({
		method: 'post',
		url: '/Invitations',
		data: invitations,
	});
	console.log('Invitations envoyées');
	return retour.data;
};

const donneInvitation = async (idDemande, email) => {
	const retour = await reseauService.donneInstanceAxios().request({
		method: 'get',
		url: '/Invitations',
		params: {
			idDemande: idDemande,
			email: email,
		},
	});
	return retour.data;
};

const rejoindreCompteEssaiCommeInvite = async (utilisateurInvite) => {
	const retour = await reseauService.donneInstanceAxios().request({
		method: 'put',
		url: '/Invitations',
		data: utilisateurInvite,
	});
	console.log('Formulaire invitation envoyé');
	return retour.data;
};

export default {
	creerDemande: (demande) => creerDemande(demande),
	verifierDemande: (demande, code) => verifierDemande(demande, code),
	donneListeFonctions: () => donneListeFonctions(),
	donneListeTailles: () => donneListeTailles(),
	donneListeSecteurs: () => donneListeSecteurs(),
	donneListeDomaines: (secteur) => donneListeDomaines(secteur),
	finaliserDemande: (parametresCompte) => finaliserDemande(parametresCompte),
	creerInvitations: (invitations) => creerInvitations(invitations),
	donneInvitation: (idDemande, email) => donneInvitation(idDemande, email),
	rejoindreCompteEssaiCommeInvite: (utilisateurInvite) => rejoindreCompteEssaiCommeInvite(utilisateurInvite),
};
