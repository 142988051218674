<template>
	<v-container fluid fill-height class="background_kn">
		<v-row>
			<v-container style="max-width:1920px" class="mx-auto px-auto">
				<v-row>
					<v-col v-if="!isMobile" cols="12" md="5" class="mt-1">
						<v-row align="center" justify="center" class="mt-8">
							<v-col cols="auto">
								<h2 class="text-pre-line" style="text-align: center">
									{{ $t('commun.genererEnvironnement') }}
								</h2>
							</v-col>
						</v-row>
						<v-row align="center" justify="center">
							<v-col cols="auto">
								<h4 style="text-align: center;font-weight: normal">
									{{ $t('commun.sansEngagements',{nbJours: nbJours}) }}
								</h4>
							</v-col>
						</v-row>

						<v-row align="center" justify="center" class="mt-10">
							<v-col cols="auto">
								<v-card color="primary" width="350" dark>
									<v-card-subtitle style="color:white !important">
										{{ $t('commun.recommendationsClients') }}
									</v-card-subtitle>
									<v-card-actions class="justify-end">
										<v-rating
											readonly
											color="yellow darken-2"
											small
											dense
											length="5"
											half-increments
											:value="retourneAffichageRating">
										</v-rating>
										<v-card-subtitle class="ml-n3 mt-1">
											{{ note }}
										</v-card-subtitle>
									</v-card-actions>
									<div class="visuel-image">
										<v-img src="../assets/visuel.png" width="400" height="auto"></v-img>
									</div>
								</v-card>
							</v-col>
						</v-row>

						<v-row align="center" justify="center" class="mt-16 pt-12">
							<v-col cols="auto">
								<h4 style="text-align: center">
									{{ $t('commun.approuveMessage',{nbUtilisateurs: nbUtilisateurs}) }}
								</h4>
							</v-col>
						</v-row>
						<v-row align="center" justify="center" class="ml-4">
							<v-col cols="auto" md="3">
								<v-img src="../assets/logo-equitim.png" max-width="200" max-height="40" contain></v-img>
							</v-col>
							<v-col cols="auto" md="3">
								<v-img src="../assets/logo-duonext.png" max-width="2000" max-height="40" contain></v-img>
							</v-col>
							<v-col cols="auto" md="3">
								<v-img src="../assets/logo-capest.png" max-width="2000" max-height="40" contain></v-img>
							</v-col>
							<v-col cols="auto" md="3">
								<v-img src="../assets/logo-ambition.png" max-width="2000" max-height="40" contain></v-img>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" md="7">
						<v-row align="center" justify="center" class="fill-height">
							<v-col cols="auto">
								<v-card :class="`px-md-10 py-md-8 ${isMobile ? 'elevation-0 transparent mt-16' : 'elevation-16'}`" width="600">
									<v-form ref="essai" lazy-validation autocomplete="on" @submit.prevent>
										<v-container>
											<v-row align="center" justify="center">
												<v-col cols="12" class="text-center mx-auto ">
													<h2>{{ $t('formulaireDemandeCompteEssai.titre') }}</h2>
												</v-col>
												<v-col cols="12" class="text-center mx-auto mt-n6">
													<h4 style="font-weight: normal">
														{{ $t('formulaireDemandeCompteEssai.sansEngagements') }}
													</h4>
												</v-col>
											</v-row>
											<v-row class="mt-6">
												<v-col cols="12">
													<div class="required">
														{{ $t('formulaireDemandeCompteEssai.nomSociete') }}
													</div>
													<v-text-field
														v-model="demande.societe"
														outlined
														dense
														hide-details="auto"
														:rules="[estObligatoire(demande.societe)]">
													</v-text-field>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12" md="6">
													<div class="required">
														{{ $t('formulaireDemandeCompteEssai.prenom') }}
													</div>
													<v-text-field
														v-model="demande.prenom"
														id="firstName"
														outlined
														dense
														hide-details="auto"
														:rules="[estObligatoire(demande.prenom)]">
													</v-text-field>
												</v-col>
												<v-col cols="12" md="6">
													<div class="required">
														{{ $t('formulaireDemandeCompteEssai.nom') }}
													</div>
													<v-text-field
														v-model="demande.nom"
														id="lastName"
														class="px-auto"
														outlined
														dense
														hide-details="auto"
														:rules="[estObligatoire(demande.nom)]">
													</v-text-field>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12" md="6">
													<div class="required">
														{{ $t('formulaireDemandeCompteEssai.email') }}
													</div>
													<v-text-field
														v-model="demande.email"
														id="email"
														outlined
														dense
														type="email"
														hide-details="auto"
														:rules="[estObligatoire(demande.email), estEmail(demande.email)]">
													</v-text-field>
												</v-col>
												<v-col cols="12" md="6">
													<div>{{ $t('formulaireDemandeCompteEssai.telephoneMobile') }}</div>
													<v-text-field
														v-model="demande.telMobile"
														id="phone"
														outlined
														dense
														hide-details="auto"
														:rules="[estNumeroTelephone(demande.telMobile)]">
													</v-text-field>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12">
													<div class="d-flex flex-row">
														<div class="d-flex">
															<v-checkbox
																v-model="demande.conditions"
																hide-details="auto"
																:rules="[estObligatoire(demande.conditions)]">
															</v-checkbox>
														</div>
														<div class="d-flex mt-6">
															<div class="caption required d-flex flex-row">
																<div class="d-flex">
																	{{ $t('formulaireDemandeCompteEssai.accepter') }}
																</div>
																<div class="text-decoration-none primary--text d-flex ml-1" style="cursor: pointer" @click.stop="affichePolitiqueConfidentialite()">
																	{{ $t('formulaireDemandeCompteEssai.politiqueConfidentialite') }}
																</div>
																<div class="d-flex ml-1">
																	{{ $t('formulaireDemandeCompteEssai.karanext') }}
																</div>
															</div>
														</div>
													</div>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12" md="auto" class="mx-auto">
													<vue-recaptcha
														id="recaptcha"
														ref="recaptcha"
														:sitekey="secret"
														:hl="'fr'"
														size="invisible"
														@verify="onVerify"
														@expired="onExpired">
														<v-btn
															block
															tile
															min-width="100px"
															color="primary">
															<span style="color:white" class="px-2 text-uppercase">
																{{ $t('formulaireDemandeCompteEssai.boutonCreerCompte') }}
															</span>
														</v-btn>
													</vue-recaptcha>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="auto" class="mx-auto">
													<a href="https://app-demo.karanext.com/#/login" class="text-decoration-underline" target="_blank">
														{{ $t('formulaireDemandeCompteEssai.lienLogin') }}
													</a>
												</v-col>
											</v-row>
										</v-container>
									</v-form>
								</v-card>
								<v-dialog v-model="estPolitiqueConfidentialiteVisible" scrollable persistent max-width="400px">
									<v-card>
										<v-card-title class="d-flex barre-titre" style="background-color:#155eef;color:white;">
											{{ $t('formulaireDemandeCompteEssai.politiqueConfidentialiteTitre') }}
											<div class="ml-auto justify-end">
												<v-btn
													color="white"
													icon
													@click="fermePolitiqueConfidentialite()">
													<v-icon color="white">mdi-close</v-icon>
												</v-btn>
											</div>
										</v-card-title>

										<v-card-text class="pa-6">
											<v-container fluid class="px-0">
												<div class="text-center black--text">
													{{ $t('formulaireDemandeCompteEssai.politiqueConfidentialiteContenu') }}
												</div>
											</v-container>
											<Spinner :est-visible="chargementLogDates"></Spinner>
										</v-card-text>
									</v-card>
								</v-dialog>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-row>
		<Spinner :est-visible="loading"></Spinner>
	</v-container>
</template>

<script>
import formulaireService from '@/services/formulaireService';
import VueRecaptcha from 'vue-recaptcha';
import Spinner from '@/components/Spinner.vue';
export default {
	components: {
		VueRecaptcha,
		Spinner
	},
	data() {
		return {
			demande: {
				societe: null,
				prenom: null,
				nom: null,
				email: null,
				informations: null,
				telMobile: null,
				conditions: null,
				recaptcha: null,
			},

			note: process.env.VUE_APP_NOTE_UTILISATEURS,
			nbJours: process.env.VUE_APP_NOMBRE_JOURS_ESSAI,
			nbUtilisateurs: process.env.VUE_APP_NOMBRE_UTILISATEURS,
			secret: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
			lienConfirmationDemande: process.env.VUE_APP_CONFIRMATION_DEMANDE_URL,

			estPolitiqueConfidentialiteVisible: false,
			loading: false,
		};
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm';
		},
		retourneAffichageRating(){
			if ((this.note>0) && (this.note<4.5)){
				if ((Math.floor(this.note)+1)-this.note<this.note-(Math.floor(this.note)))
					return (Math.floor(this.note)+1);
				else return Math.floor(this.note)+0.5;
			}
			else if ((this.note>4.5) && (this.note<5)){
				return 4.5;
			}
			else return this.note;
		},
	},
	methods: {
		estObligatoire(valeur) {
			return !!valeur || valeur === 0 || this.$t('champs.champsObligatoire');
		},
		estEmail(valeur) {
			const pattern =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return !valeur || pattern.test(valeur) || this.$t('champs.champsEmail');
		},
		estNumeroTelephone(valeur) {
			if (valeur && valeur.trim() !== '') {
				const pattern = /^(\+|00)?[0-9]+$/;
				return (!!valeur && pattern.test(valeur)) || this.$t('champs.champsTelephone');
			}
			return true;
		},
		async onVerify(response) {
			if (!this.$refs.essai.validate()) return;
			this.loading = true;
			this.demande.recaptcha = response;
			await formulaireService.creerDemande(this.demande);
			this.$store.commit('emailAdministrateur', this.demande.email);
			window.location.href = this.lienConfirmationDemande;
			this.loading = false;
		},
		onExpired() {
			this.$refs.recaptcha.reset();
		},
		affichePolitiqueConfidentialite() {
			this.estPolitiqueConfidentialiteVisible = true;
		},
		fermePolitiqueConfidentialite() {
			this.estPolitiqueConfidentialiteVisible = false;
		}
	},
};
</script>

<style scoped>
.v-input--checkbox > .v-input__control > .v-input__slot {
	align-items: flex-start !important;
}
.visuel-image {
  position: absolute;
  top: 15px;
  right: 90px;
  display: inline;
}
</style>
