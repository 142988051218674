<template>
	<v-container fluid fill-height class="background_kn">
		<v-row align="center" justify="center" class="fill-height mt-n16">
			<v-col cols="auto">
				<v-card :class="`px-md-10 py-md-8 ${isMobile ? 'elevation-0 transparent mt-16' : 'elevation-16'}`" width="550">
					<v-form v-if=" messageVerificationDemande == 'OLD_ACTIVE'" ref="invitations" lazy-validation @submit.prevent>
						<v-container>
							<v-row>
								<v-col cols="12">
									<h3 style="font-weight: normal">
										{{ $t('invitation.titre') }}
									</h3>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12">
									<h2>{{ $t('invitation.inviterCollaborateur') }}</h2>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12">
									<h5 class="text-pre-line">
										{{ $t('invitation.explicationInvitation') }}
									</h5>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12">
									<div>{{ $t('invitation.emailLabel') }}</div>
									<v-combobox
										v-model="invitations.emails"
										ref="myCombobox"
										class="mt-3"
										outlined
										multiple
										chips
										clearable
										deletable-chips
										hide-details="auto"
										append-icon=""
										height="150px"
										:delimiters="[';', ',', ' ']"
										:rules="[valideEmail(invitations.emails)]"
										@paste="donneListeEmailsCopies(...arguments)"
										@keydown.enter.prevent>
										<template #selection="{ item, on, parent }">
											<v-chip color="primary" v-bind="on">
												<span class="pr-2">{{ item }}</span>
												<v-icon small @click="parent.selectItem(item)">mdi-close-circle</v-icon>
											</v-chip>
										</template>
									</v-combobox>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="auto" class="mx-auto mt-6">
									<v-btn
										block
										tile
										min-width="100px"
										color="primary"
										@click="envoyerInvitations()">
										<span style="color:white" class="px-2 text-uppercase">
											{{ $t('invitation.boutonInviterUtilisateurs') }}
										</span>
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
					<div v-else-if="messageVerificationDemande == 'OK'">
						<v-row align="center" justify="center">
							<v-col cols="auto">
								<h3>
									{{ $t('invitation.oldActive') }}
								</h3>
							</v-col>
						</v-row>
					</div>
					<div v-else-if="messageVerificationDemande == 'OLD_ENCOURS'">
						<v-row align="center" justify="center">
							<v-col cols="auto">
								<h3>
									{{ $t('invitation.oldEnCours') }}
								</h3>
							</v-col>
						</v-row>
					</div>
					<div v-else-if="messageVerificationDemande == 'OLD_EXPIREE'">
						<v-row align="center" justify="center">
							<v-col cols="auto">
								<h3>
									{{ $t('invitation.oldExpire') }}
								</h3>
							</v-col>
						</v-row>
					</div>
				</v-card>
			</v-col>
		</v-row>
		<Spinner v-show="loading"></Spinner>
	</v-container>
</template>

<script>
import formulaireService from '@/services/formulaireService';
import Spinner from '@/components/Spinner.vue';
export default {
	name: 'InvitationsUtilisateurs',
	components: {
		Spinner,
	},
	props: {
		demande: {
			type: String,
		},
		code: {
			type: String,
		},
	},
	data() {
		return {
			invitations: {
				idDemande: this.demande,
				codeVerification: this.code,
				emails: [],
			},

			messageVerificationDemande: '',

			loading: false,
		};
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.name == 'xs';
		},
	},
	methods: {
		valideEmail(value) {
			const emailRegex = /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			const validEmails = value.filter((email) => emailRegex.test(email));

			if (validEmails.length !== value.length) {
				this.invitations.emails = validEmails;
				this.envoi = this.invitations.emails.length > 0;
				return this.$t('invitation.emailValide');
			}

			this.envoi = this.invitations.emails.length > 0;
			return true;
		},
		donneListeEmailsCopies(event) {
			const pastedText = event.clipboardData.getData('text/plain');
			const separators = [';', ',', ' '];
			const chevronRegex = /<([^<>]+)>/g;

			const emails = pastedText
				.split(new RegExp(`[${separators.join('')}]`))
				.flatMap((item) => item.trim().match(chevronRegex) || [item.trim()])
				.map((item) => item.replace(/[<>]/g, '').trim());

			const uniqueEmails = emails.filter((email) => email !== '' && !this.invitations.emails.includes(email));
			this.invitations.emails = [...this.invitations.emails, ...uniqueEmails];

			event.preventDefault();
		},
		async envoyerInvitations() {
			if (!this.$refs.invitations.validate()) return;
			this.loading = true;
			await formulaireService.creerInvitations(this.invitations);
			this.$router.push({ name: 'formulaireDemande' });
		},
	},
	async mounted() {
		this.messageVerificationDemande = (await formulaireService.verifierDemande(this.demande, this.code)).resultat;
		if (this.messageVerificationDemande === 'NOK') this.$router.push({ name: 'formulaireDemande' });
		this.$nextTick(() => {
			const combobox = this.$refs.myCombobox;
			if (combobox) {
				const input = combobox.$refs.input;
				if (input) {
					input.setSelectionRange(0, 0);
				}
			}
		});
	},
};
</script>
