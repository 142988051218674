<template>
	<v-app>
		<v-main>
			<v-app-bar
				absolute
				color="transparent"
				elevation="0">
				<v-row align="start" class="mt-6">
					<v-col cols="12" class="ml-1">
						<v-img src="@/assets/logo karanext.png" max-width="200" max-height="40" contain></v-img>
					</v-col>
					<v-col cols="12" class="ml-1 mt-n6">
						<v-btn x-small text @click="retourAuSite()">
							<v-icon x-small>mdi-arrow-left</v-icon>{{ $t('commun.retour') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-app-bar>
			<router-view></router-view>
		</v-main>
	</v-app>
</template>

<script>
export default {
	name: 'App',
	methods: {
		retourAuSite() {
			window.location.href = 'https://www.karanext.com/';
		}
	},
};
</script>

<style>
.background_kn {
    background-image: url('@/assets/background_desktop.png');
	background-color: #d1e0ff!important;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
}

.required::after {
	content: ' *';
	color: red;
}
</style>