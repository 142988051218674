import axios from 'axios';
// import router from '@/router'

const donneInstanceAxios = () => {
	const instance = axios.create({
		baseURL: process.env.VUE_APP_API_URL,
		headers: {
			'Content-Type': 'application/json',
		}
	});
	// instance.interceptors.response.use((response) => { return response; }, (error) => {
	//     if(error.response.status === 401) {
	//         router.push('login');
	//     }
	//     if(error.response.status === 500) {
	//         throw new Error(error.response.data.message);
	//     }
	// });
	return instance;
};

export default { donneInstanceAxios: () => donneInstanceAxios() };