import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import calendly from './plugins/calendly';
import VueRecaptcha from './plugins/vue-recaptcha';

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	i18n,
	calendly,
	VueRecaptcha ,
	render: (h) => h(App)
}).$mount('#app');
